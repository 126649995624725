import React, { useState, useEffect } from 'react';
import { Container, Typography, CircularProgress, Box, Button, Dialog, TextField, Autocomplete, DialogActions, DialogTitle, Grid } from '@mui/material';
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import api from '../api';  // Ajusta la ruta según tu estructura
import { useAuth } from '../contexts/AuthContext';

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import '../components/BigCalendar/calendarStyles.css';

const localizer = dayjsLocalizer(dayjs);
const DnDCalendar = withDragAndDrop(Calendar);

const AppointmentsPage = () => {
  const { clinic } = useAuth();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [formData, setFormData] = useState({
    patient: null,
    treatment: null,
  });
  const [patients, setPatients] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [syncLoading, setSyncLoading] = useState(false);
  const [syncError, setSyncError] = useState(null);
  const [googleAuthUrl, setGoogleAuthUrl] = useState('');
  const [isGoogleConnected, setIsGoogleConnected] = useState(false);
  const [googleAuthStatus, setGoogleAuthStatus] = useState('unknown'); // 'unknown', 'connected', 'disconnected'

  useEffect(() => {
    if (clinic) {
      fetchAppointments();
      fetchPatientsAndTreatments();
      checkGoogleConnection();
    }
  }, [clinic]);

  const fetchAppointments = async () => {
    try {
      const response = await api.get(`/appointments?clinicId=${clinic._id}`);
      const appointments = response.data.map((appointment) => ({
        id: appointment._id,
        title: appointment.description,
        start: new Date(appointment.startDate),
        end: new Date(appointment.endDate),
        treatment: appointment.treatment,
        patientId: appointment.patientId,
        leadId: appointment.leadId,
        description: appointment.description,
        status: appointment.status,
        className: appointment.status === 'canceled' ? 'canceled' : ''
      }));
      setEvents(appointments);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching appointments:", error);
      setError("Error fetching appointments");
      setLoading(false);
    }
  };

  const fetchPatientsAndTreatments = async () => {
    try {
      const [patientsResponse, treatmentsResponse] = await Promise.all([
        api.get('/patients'),
        api.get('/treatments')
      ]);
      setPatients(patientsResponse.data);
      setTreatments(treatmentsResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onEventResize = async ({ event, start, end }) => {
    try {
      const appointmentLength = dayjs(end).diff(dayjs(start), 'minute');

      const updatedEvent = {
        startDate: start,
        endDate: end,
        appointmentLength,
        treatment: event.treatment?._id || event.treatment,
        description: event.title,
      };

      await api.patch(`/appointments/${event.id}`, updatedEvent);

      setEvents((prevEvents) =>
        prevEvents.map((evt) => (evt.id === event.id ? { ...evt, start, end } : evt))
      );
    } catch (error) {
      console.error("Error updating appointment:", error);
    }
  };

  const onEventDrop = async ({ event, start, end }) => {
    try {
      const appointmentLength = dayjs(end).diff(dayjs(start), 'minute');

      const updatedEvent = {
        startDate: start,
        endDate: end,
        appointmentLength,
        treatment: event.treatment?._id || event.treatment,
        description: event.title,
      };

      await api.patch(`/appointments/${event.id}`, updatedEvent);

      setEvents((prevEvents) =>
        prevEvents.map((evt) => (evt.id === event.id ? { ...evt, start, end } : evt))
      );
    } catch (error) {
      console.error("Error updating appointment:", error);
    }
  };

  const handleSelectSlot = (slotInfo) => {
    setSelectedSlot(slotInfo);
    setOpenDialog(true);
  };

  const handleCreateAppointment = async () => {
    if (!validateForm()) {
      alert("All fields are required.");
      return;
    }
    const { start, end } = selectedSlot;
    const appointmentLength = dayjs(end).diff(dayjs(start), 'minute');
    const newAppointment = {
      startDate: start,
      endDate: end,
      appointmentLength,
      treatment: formData.treatment._id,
      description: `${formData.patient.firstName} ${formData.patient.lastName} - ${formData.treatment.name}`,
      patientId: formData.patient._id,
    };

    try {
      const response = await api.post('/appointments', newAppointment);
      setEvents((prevEvents) => [...prevEvents, {
        id: response.data.appointment._id,
        title: response.data.appointment.description,
        start: new Date(response.data.appointment.startDate),
        end: new Date(response.data.appointment.endDate),
        treatment: response.data.appointment.treatment,
        patient: response.data.appointment.patient,
        description: response.data.appointment.description,
      }]);
      setOpenDialog(false);
    } catch (error) {
      console.error('Error creating appointment:', error);
    }
  };

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
  };

  const handleCancelAppointment = async () => {
    try {
      await api.patch(`/appointments/${selectedEvent.id}`, { status: 'canceled' });
      setEvents((prevEvents) => prevEvents.map(evt => 
        evt.id === selectedEvent.id ? { ...evt, status: 'canceled', className: 'canceled' } : evt
      ));
      setSelectedEvent(null);
    } catch (error) {
      console.error("Error al cancelar la cita:", error);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setFormData({ patient: null, treatment: null });
  };

  const validateForm = () => {
    return formData.patient && formData.treatment;
  };

  const eventPropGetter = (event) => {
    let className = 'rbc-event';
    
    if (event.status === 'canceled') {
      className += ' canceled';
    }

    return { className };
  };

  const handleSyncWithGoogle = async () => {
    setSyncLoading(true);
    setSyncError(null);
    try {
      const response = await api.post('/appointments/sync-google-calendar');
      await fetchAppointments(); // Recargar las citas después de la sincronización
      alert('Sincronización con Google Calendar completada con éxito');
    } catch (error) {
      console.error('Error al sincronizar con Google Calendar:', error);
      if (error.response && error.response.status === 401 && error.response.data.needsAuth) {
        setGoogleAuthStatus('disconnected');
        alert('Se requiere autorización de Google Calendar. Por favor, conecte su cuenta.');
      } else {
        setSyncError('Error en la sincronización. Por favor, intente de nuevo.');
      }
    } finally {
      setSyncLoading(false);
    }
  };

  const checkGoogleConnection = async () => {
    try {
      const response = await api.get('/auth/google/status');
      setGoogleAuthStatus(response.data.isFullyConnected ? 'connected' : 'disconnected');
    } catch (error) {
      console.error('Error checking Google connection:', error);
      setGoogleAuthStatus('error');
    }
  };

  const handleConnectGoogle = async () => {
    try {
      const response = await api.get('/auth/google/auth');
      window.location.href = response.data.authUrl;
    } catch (error) {
      console.error('Error getting Google auth URL:', error);
      setGoogleAuthStatus('error');
    }
  };

  const handleDisconnectGoogle = async () => {
    try {
      await api.post('/auth/google/revoke');
      setGoogleAuthStatus('disconnected');
      alert('Google Calendar disconnected successfully');
    } catch (error) {
      console.error('Error disconnecting Google Calendar:', error);
      setGoogleAuthStatus('error');
      alert('Error disconnecting Google Calendar');
    }
  };

  const handleReactivateAppointment = async () => {
    try {
      await api.patch(`/appointments/${selectedEvent.id}`, { status: 'pending' });
      setEvents((prevEvents) => prevEvents.map(evt => 
        evt.id === selectedEvent.id ? { ...evt, status: 'pending', className: '' } : evt
      ));
      // Cerrar el modal después de reactivar la cita
      setSelectedEvent(null);
    } catch (error) {
      console.error("Error al reactivar la cita:", error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      <Typography variant="h4">Appointments</Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        {googleAuthStatus === 'connected' ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSyncWithGoogle}
              disabled={syncLoading}
            >
              {syncLoading ? 'Synchronizing...' : 'Sync with Google Calendar'}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleDisconnectGoogle}
            >
              Disconnect Google Calendar
            </Button>
          </>
        ) : googleAuthStatus === 'disconnected' ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleConnectGoogle}
          >
            Connect Google Calendar
          </Button>
        ) : googleAuthStatus === 'error' ? (
          <Typography color="error">Error with Google Calendar connection</Typography>
        ) : (
          <CircularProgress size={24} />
        )}
        {syncError && <Typography color="error">{syncError}</Typography>}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ mt: 4 }}>
          <DnDCalendar
              selectable
              defaultDate={dayjs().toDate()}
              defaultView="week"
              events={events}
              localizer={localizer}
              onEventDrop={onEventDrop}
              onEventResize={onEventResize}
              onSelectSlot={handleSelectSlot}
              onSelectEvent={handleEventSelect}
              resizable
              draggableAccessor={() => true}
              style={{ height: "70vh" }}
              eventPropGetter={eventPropGetter}
            />
          </Box>
        </Grid>
      </Grid>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <Box sx={{ p: 3 }}>
          <Typography variant="h6">Create New Appointment</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                options={patients}
                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                onChange={(event, newValue) => setFormData({ ...formData, patient: newValue })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Patient"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={treatments}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => setFormData({ ...formData, treatment: newValue })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Treatment"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button
              onClick={handleCreateAppointment}
              variant="contained"
              color="primary"
              disabled={!validateForm()}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Dialog>

      {selectedEvent && (
        <Dialog open={!!selectedEvent} onClose={() => setSelectedEvent(null)}>
          <DialogTitle>Event Details</DialogTitle>
          <Box sx={{ p: 3 }}>
            <Typography>
              {selectedEvent.patientId ? (
                <>
                  <strong>Patient:</strong> {`${selectedEvent.patientId.firstName} ${selectedEvent.patientId.lastName}`}
                </>
              ) : selectedEvent.leadId ? (
                <>
                  <strong>Lead:</strong> {`${selectedEvent.leadId.firstName} ${selectedEvent.leadId.lastName}`}
                </>
              ) : null}
            </Typography>
            <Typography>
              <strong>Treatment:</strong> {selectedEvent.treatment?.name || 'N/A'}
            </Typography>
            <Typography>
              <strong>Start:</strong> {dayjs(selectedEvent.start).format('YYYY-MM-DD HH:mm')}
            </Typography>
            <Typography>
              <strong>End:</strong> {dayjs(selectedEvent.end).format('YYYY-MM-DD HH:mm')}
            </Typography>
            <Typography>
              <strong>Status:</strong> {selectedEvent.status === 'canceled' ? 'Canceled' : 'Active'}
            </Typography>
          </Box>
          <DialogActions>
            <Button onClick={() => setSelectedEvent(null)}>Close</Button>
            {selectedEvent.status === 'canceled' ? (
              <Button onClick={handleReactivateAppointment} color="primary" variant="contained">
                Reactivate Appointment
              </Button>
            ) : (
              <Button onClick={handleCancelAppointment} color="secondary" variant="contained">
                Cancel Appointment
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};

export default AppointmentsPage;