import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Box,
  CircularProgress, Stepper, Step, StepLabel, TextField, Autocomplete
} from '@mui/material';
import api from '../api';
import { useAuth } from '../contexts/AuthContext';
import TiptapEditor from './TiptapEditor/TiptapEditor'; // Importamos el editor Tiptap

const steps = ['Select Template', 'Review & Edit Consent'];

const ConsentSendDialog = ({ open, onClose, patientId }) => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [consentContent, setConsentContent] = useState('');
  const [loading, setLoading] = useState(false);
  const { clinic } = useAuth();
  const [informedConsent, setInformedConsent] = useState(null);
  const [patient, setPatient] = useState(null); // Nuevo estado para almacenar los datos del paciente

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const templateResponse = await api.get('/consent-templates', { params: { clinicId: clinic._id } });
        setTemplates(templateResponse.data);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };

    const fetchPatient = async () => {
      try {
        const patientResponse = await api.get(`/patients/${patientId}`);
        setPatient(patientResponse.data);
      } catch (error) {
        console.error('Error fetching patient:', error);
      }
    };

    if (clinic) {
      fetchTemplates();
    }

    if (patientId) {
      fetchPatient();
    }
  }, [clinic, patientId]);

  const handleNext = async () => {
    if (activeStep === 0 && selectedTemplate) {
      try {
        const response = await api.post('/informed-consents', {
          consentTemplateId: selectedTemplate._id,
          patientId,
          clinic: clinic._id,
          treatmentId: selectedTemplate.treatmentId._id,
        });

        const createdConsent = response.data;
        // Reemplazar los placeholders con los valores reales
        let content = createdConsent.content;
        content = content.replace(/{{user\.name}}/g, clinic.name);
        content = content.replace(/{{user\.userIdNumber}}/g, clinic.userIdNumber || '');
        content = content.replace(/{{patient\.firstName}}/g, patient.firstName);
        content = content.replace(/{{patient\.lastName}}/g, patient.lastName);
        content = content.replace(/{{patient\.idNumber}}/g, patient.idNumber);
        content = content.replace(/{{treatment\.name}}/g, selectedTemplate.treatmentId.name);
        content = content.replace(/{{date}}/g, new Date().toLocaleDateString());

        setConsentContent(content);
        setInformedConsent(createdConsent);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        console.error('Error creating informed consent:', error);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSend = async () => {
    setLoading(true);
    try {
      // Actualizar el contenido del consentimiento informado antes de enviar para firma
      await api.put(`/informed-consents/${informedConsent._id}`, {
        content: consentContent,
      });

      // Luego, generar el token de firma
      const tokenResponse = await api.post('/signature/token', {
        informedConsentId: informedConsent._id,
        patientId,
        clinicId: clinic._id,
      });

      const { signatureUrl } = tokenResponse.data;

      // Enviar el enlace de firma por WhatsApp
      await api.post('/whatsapp/send', {
        phoneNumber: patient.phone, // Asegurarse de que se envía el número de teléfono
        patientId,
        type: 'template',
        templateName: 'consentimiento_informado', // Nombre del template de WhatsApp
        parameters: [
          { type: 'text', text: patient.firstName + ' ' + patient.lastName }, // Nombre del usuario
          { type: 'text', text: selectedTemplate.treatmentId.name }, // Nombre del tratamiento
          { type: 'url', text: signatureUrl } // URL para la firma
        ],
      });

      setLoading(false);
      onClose();
    } catch (error) {
      console.error('Error sending consent:', error.response ? error.response.data : error.message);
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', p: 3 }}>
        <Box sx={{ width: '100%', maxWidth: '600px', backgroundColor: 'transparent' }}>
          <DialogTitle sx={{ textAlign: 'center', mb: 2 }}>Send Informed Consent</DialogTitle>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <DialogContent>
            {activeStep === 0 ? (
              <Box>
                <Autocomplete
                  options={templates}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, value) => setSelectedTemplate(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Consent Template" variant="outlined" fullWidth margin="normal" />
                  )}
                />
              </Box>
            ) : (
              <TiptapEditor content={consentContent} setContent={setConsentContent} />
            )}
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            {activeStep === 0 ? (
              <Button onClick={handleNext} color="primary" variant="contained" disabled={!selectedTemplate}>
                Next
              </Button>
            ) : (
              <>
                <Button onClick={handleBack} color="primary">
                  Back
                </Button>
                <Button onClick={handleSend} color="primary" variant="contained" disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : 'Send'}
                </Button>
              </>
            )}
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConsentSendDialog;
