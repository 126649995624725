import React, { useEffect, useState } from 'react';
import { 
  Container, Typography, Table, TableHead, TableBody, TableRow, TableCell, 
  Button, Box, IconButton, Card, CardContent, TextField, Tooltip, 
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  TablePagination, Snackbar, Alert, Skeleton, CircularProgress, TableContainer, Paper, Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Delete as DeleteIcon, Edit as EditIcon, Search as SearchIcon } from '@mui/icons-material';
import AddTreatment from '../components/AddTreatment';
import { useAuth } from '../contexts/AuthContext';
import api from '../api';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  cursor: 'pointer',
}));

export default function TreatmentsPage() {
  const { clinic } = useAuth();
  const [treatments, setTreatments] = useState([]);
  const [filteredTreatments, setFilteredTreatments] = useState([]);
  const [isAddTreatmentDialogOpen, setAddTreatmentDialogOpen] = useState(false);
  const [isEditTreatmentDialogOpen, setEditTreatmentDialogOpen] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTreatments = async () => {
      try {
        setIsLoading(true);
        const response = await api.get(`/treatments?clinicId=${clinic._id}`);
        setTreatments(response.data);
        setFilteredTreatments(response.data);
      } catch (error) {
        console.error('Error fetching treatments:', error);
        showSnackbar('Error fetching treatments', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    if (clinic) {
      fetchTreatments();
    }
  }, [clinic]);

  useEffect(() => {
    const filtered = treatments.filter(treatment => 
      treatment.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTreatments(filtered);
    setPage(0);
  }, [searchTerm, treatments]);

  const handleAddTreatmentClick = () => setAddTreatmentDialogOpen(true);
  const handleAddTreatmentClose = () => setAddTreatmentDialogOpen(false);
  const handleEditTreatmentClick = (treatment) => {
    setSelectedTreatment(treatment);
    setEditTreatmentDialogOpen(true);
  };
  const handleEditTreatmentClose = () => {
    setSelectedTreatment(null);
    setEditTreatmentDialogOpen(false);
  };

  const handleTreatmentAdded = (newTreatment) => {
    setTreatments([...treatments, newTreatment]);
    showSnackbar('Treatment added successfully', 'success');
  };

  const handleTreatmentEdited = (editedTreatment) => {
    setTreatments(treatments.map(t => t._id === editedTreatment._id ? editedTreatment : t));
    showSnackbar('Treatment updated successfully', 'success');
  };

  const handleDeleteClick = (treatment) => {
    setSelectedTreatment(treatment);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await api.delete(`/treatments/${selectedTreatment._id}?clinicId=${clinic._id}`);
      setTreatments(treatments.filter((t) => t._id !== selectedTreatment._id));
      showSnackbar('Treatment deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting treatment:', error);
      showSnackbar('Error deleting treatment', 'error');
    } finally {
      setDeleteConfirmationOpen(false);
      setSelectedTreatment(null);
    }
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (isLoading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        {/* Page title */}
        <Grid item xs={12}>
          <Typography variant="h4" component="h1" gutterBottom>
            Treatments
          </Typography>
        </Grid>

        {/* Search and Add Treatment button */}
        <Grid item xs={12} sm={10}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search treatments..."
            InputProps={{
              startAdornment: <SearchIcon color="action" />,
            }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button variant="contained" color="primary" onClick={handleAddTreatmentClick} fullWidth>
            Add Treatment
          </Button>
        </Grid>

        {/* Treatments table */}
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Treatment Name</TableCell>
                  <TableCell>Duration (minutes)</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTreatments
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((treatment) => (
                    <StyledTableRow key={treatment._id}>
                      <TableCell>{treatment.name}</TableCell>
                      <TableCell>{treatment.duration}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="Edit">
                          <IconButton onClick={() => handleEditTreatmentClick(treatment)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton onClick={() => handleDeleteClick(treatment)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredTreatments.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>

      <AddTreatment 
        open={isAddTreatmentDialogOpen} 
        onClose={handleAddTreatmentClose} 
        onTreatmentAdded={handleTreatmentAdded} 
      />
      
      {selectedTreatment && (
        <AddTreatment 
          open={isEditTreatmentDialogOpen} 
          onClose={handleEditTreatmentClose} 
          onTreatmentAdded={handleTreatmentEdited}
          initialTreatment={selectedTreatment}
        />
      )}
      
      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this treatment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
      
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}
