import React, { useState, useEffect } from 'react';
import { 
  TextField, Button, Box, Typography, Card, CardContent, Grid, 
  IconButton, InputAdornment, Snackbar, Alert
} from '@mui/material';
import { 
  Save, Visibility, VisibilityOff, Phone, Key, Link, Assistant
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import api from '../api';

function AISettings() {
  const { clinic } = useAuth();
  const [whatsappConfig, setWhatsappConfig] = useState({
    phoneNumberId: '',
    accessToken: '',
  });
  const [webhookURL, setWebhookURL] = useState('');
  const [showAccessToken, setShowAccessToken] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    if (clinic) {
      setWhatsappConfig({
        phoneNumberId: clinic.phoneNumberId || '',
        accessToken: clinic.accessToken || '',
      });
      if (clinic.webhookURL) {
        setWebhookURL(clinic.webhookURL);
      }
    }
  }, [clinic]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWhatsappConfig(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await api.put(`/clinics/update-clinic-config/${clinic._id}`, whatsappConfig);
      showSnackbar('Configuration saved successfully', 'success');
    } catch (error) {
      console.error('Error saving the configuration:', error);
      showSnackbar('Could not save the configuration', 'error');
    }
  };

  const handleGenerateWebhook = async () => {
    try {
      const response = await api.post(`/clinics/${clinic._id}/generate-webhook`);
      setWebhookURL(response.data.webhookURL);
      showSnackbar('Webhook URL generated successfully', 'success');
    } catch (error) {
      console.error('Error generating the webhook URL:', error);
      showSnackbar('Could not generate the webhook URL', 'error');
    }
  };

  const handleCreateAssistant = async () => {
    try {
      await api.post(`/clinics/${clinic._id}/create-assistant`);
      showSnackbar('OpenAI Assistant created successfully', 'success');
    } catch (error) {
      console.error('Error creating the assistant:', error);
      showSnackbar('Could not create the OpenAI Assistant', 'error');
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', mt: 4, p: 2 }}>
      <Typography variant="h2" gutterBottom>AI Settings</Typography>
      
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>OpenAI Assistant Configuration</Typography>
          <Button
            variant="contained"
            color={clinic.openAIAssistantId ? 'success' : 'primary'}
            onClick={handleCreateAssistant}
            startIcon={<Assistant />}
            sx={{ mt: 2 }}
          >
            {clinic.openAIAssistantId ? 'Assistant Configured' : 'Create OpenAI Assistant'}
          </Button>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>WhatsApp Configuration</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="WhatsApp Phone Number ID"
                name="phoneNumberId"
                value={whatsappConfig.phoneNumberId}
                onChange={handleChange}
                fullWidth
                sx={{ mt: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="WhatsApp Access Token"
                name="accessToken"
                value={whatsappConfig.accessToken}
                onChange={handleChange}
                fullWidth
                type={showAccessToken ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Key />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowAccessToken(!showAccessToken)}
                        edge="end"
                      >
                        {showAccessToken ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSave}
            startIcon={<Save />}
            sx={{ mt: 3 }}
          >
            Save Configuration
          </Button>

          <Box mt={4}>
            <Typography variant="h6" gutterBottom>Webhook URL</Typography>
            {webhookURL ? (
              <TextField
                value={webhookURL}
                fullWidth
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Link />
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Button 
                variant="outlined" 
                color="secondary" 
                onClick={handleGenerateWebhook}
                startIcon={<Link />}
              >
                Generate Webhook URL
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AISettings;
