import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../api';
import { Container, Typography, Grid, IconButton, CircularProgress, Skeleton, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PatientActions from '../components/PatientActions';
import PatientDetails from '../components/PatientDetails';
import ClinicalHistoryList from '../components/ClinicalHistoryList';
import PatientPaymentForm from '../components/PatientPaymentForm';
import AppointmentModal from '../components/AppointmentModal';
import EditPatientModal from '../components/EditPatientModal';
import ClinicalHistoryForm from '../components/ClinicalHistoryForm';
import { useAuth } from '../contexts/AuthContext';
import PendingBalanceCard from '../components/PendingBalanceCard';
import ConsentSendDialog from '../components/ConsentSendDialog';
import ConsentTable from '../components/ConsentTable';
import AttachmentList from '../components/AttachmentList';
import { Button } from '@mui/material';
import FileUploadModal from '../components/FileUploadModal';
import { Snackbar, Alert } from '@mui/material';

const PatientDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { clinic } = useAuth();
  const [patient, setPatient] = useState(null);
  const [totalPendingAmount, setTotalPendingAmount] = useState(0);
  const [openModal, setOpenModal] = useState('');
  const [histories, setHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Añadimos este estado
  const [refreshAttachments, setRefreshAttachments] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const fetchPatient = useCallback(async () => {
    try {
      const response = await api.get(`/patients/${id}?clinicId=${clinic._id}`);
      setPatient(response.data);
    } catch (error) {
      console.error('Error fetching patient:', error);
    }
  }, [id, clinic]);

  const fetchHistories = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.get(`/clinicalHistories/patient/${id}?clinicId=${clinic._id}`);
      setHistories(response.data);
    } catch (error) {
      console.error('Error fetching clinical histories:', error);
      setHistories([]);
    } finally {
      setIsLoading(false);
    }
  }, [id, clinic._id]);

  const fetchPendingBalance = useCallback(async () => {
    try {
      const response = await api.get(`/clinicalHistories/patient/${id}/pending-balance`);
      setTotalPendingAmount(response.data.totalPendingBalance);
    } catch (error) {
      console.error('Error fetching pending balance:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchPatient();
    fetchHistories();
    fetchPendingBalance();
  }, [fetchPatient, fetchHistories, fetchPendingBalance]);

  const handleOpenModal = (modal) => {
    setOpenModal(modal);
  };

  const handleCloseModal = useCallback(() => {
    setOpenModal('');
    fetchHistories(); // Actualizar las historias clínicas al cerrar el modal
  }, [fetchHistories]);

  const handlePatientUpdated = (updatedPatient) => {
    setPatient(updatedPatient);
  };

  const handleHistoryAdded = useCallback((newHistory, error) => {
    if (error) {
      setSnackbar({
        open: true,
        message: `Error al agregar historia clínica: ${error.message}`,
        severity: 'error'
      });
    } else {
      setHistories((prevHistories) => [...prevHistories, newHistory]);
      fetchPendingBalance();
      fetchHistories();
      setSnackbar({
        open: true,
        message: 'Historia clínica agregada exitosamente',
        severity: 'success'
      });
    }
  }, [fetchPendingBalance, fetchHistories]);

  const handlePaymentAdded = () => {
    fetchHistories();
    fetchPendingBalance();
    handleCloseModal();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      await api.post(`/attachments/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setRefreshAttachments(prev => !prev);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleFileUploadSuccess = () => {
    setRefreshAttachments(prev => !prev);
    handleCloseModal();
    setSnackbar({
      open: true,
      message: 'File uploaded successfully',
      severity: 'success'
    });
  };

  const handleFileUploadError = (errorMessage) => {
    setSnackbar({
      open: true,
      message: `Error uploading file: ${errorMessage}`,
      severity: 'error'
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (!patient) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {/* Header de la página */}
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <IconButton onClick={() => navigate('/patients')} aria-label="back to patients">
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h4">{`${patient.firstName} ${patient.lastName}`}</Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Acciones del paciente */}
        <Grid item xs={12}>
          <PatientActions handleOpenModal={handleOpenModal} />
        </Grid>

        {/* Detalles y historial clínico del paciente */}
        <Grid item xs={12} md={6}>
          <Box mb={3}>  {/* Añade un margen inferior */}
            <PendingBalanceCard amount={totalPendingAmount} />
          </Box>
          <Box mb={3}>  {/* Añade un margen inferior */}
            <PatientDetails patient={patient} />
          </Box>
          <Box mt={3}>  {/* Añade un margen superior */}
            <ConsentTable patientId={patient._id} />
          </Box>
          <Box mt={3}>  {/* Añade un margen superior */}
            <AttachmentList patientId={id} refreshTrigger={refreshAttachments} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <ClinicalHistoryList 
            patientId={patient._id} 
            histories={histories} // Asegúrate de que esto esté aquí
            onHistoryAdded={handleHistoryAdded}
            refreshHistories={fetchHistories}
            isLoading={isLoading} // Pasamos isLoading como prop
          />
        </Grid>

      </Grid>

      {/* Modal Management */}
      {openModal === 'payment' && (
        <PatientPaymentForm
          open={true}
          onClose={handleCloseModal}
          onPaymentAdded={handlePaymentAdded}
        />
      )}
      {openModal === 'appointment' && (
        <AppointmentModal
          open={true}
          onClose={handleCloseModal}
          patientId={patient._id}
        />
      )}
      {openModal === 'edit' && (
        <EditPatientModal
          open={true}
          onClose={handleCloseModal}
          patient={patient}
          onPatientUpdated={handlePatientUpdated}
        />
      )}
      {openModal === 'treatment' && (
        <ClinicalHistoryForm
          open={true}
          onClose={handleCloseModal}
          patientId={patient._id}
          onHistoryAdded={handleHistoryAdded}
          showPaymentFields={true}
        />
      )}
      {openModal === 'consent' && (
        <ConsentSendDialog
          open={true}
          onClose={handleCloseModal}
          patientId={patient._id}
        />
      )}
      {openModal === 'uploadFiles' && (
        <FileUploadModal
          open={true}
          onClose={handleCloseModal}
          patientId={id}
          onUploadSuccess={handleFileUploadSuccess}
          onUploadError={handleFileUploadError}
        />
      )}

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PatientDetailPage;