import React, { useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Typography,
  IconButton,
  Collapse,
  Button,
} from '@mui/material';
import {
  Group,
  CalendarToday,
  Logout,
  Settings,
  ExpandLess,
  ExpandMore,
  MedicalServices,
  Inventory,
  Business,
  Schedule,
  AttachMoney,
  AccountBalance,
  TrendingUp,
  Assessment,
  UploadFile,
  Search,
  Thermostat,
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import SearchModal from './SearchModal';

function Sidebar({ closeDrawer }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [openSettings, setOpenSettings] = useState(false);
  const [openFinancials, setOpenFinancials] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const { clinic, user, logout } = useAuth();

  const handleClickSettings = () => {
    setOpenSettings(!openSettings);
  };

  const handleClickFinancials = () => {
    setOpenFinancials(!openFinancials);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const openSearchModal = () => {
    if (!isSearchModalOpen) {
      setIsSearchModalOpen(true);
    }
  };

  const closeSearchModal = () => {
    if (isSearchModalOpen) {
      setIsSearchModalOpen(false);
    }
  };

  const handleSearchClick = (event) => {
    event.preventDefault();
    openSearchModal();
  };

  const sidebarContent = (
    <Box
      sx={{
        width: 240,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        overflowY: 'auto',
        backgroundColor: 'white',
        padding: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" sx={{ ml: 2 }}>
          {clinic?.name}
        </Typography>
      </Box>

      <Button
        onClick={handleSearchClick}
        variant="outlined"
        startIcon={<Search />}
        fullWidth
        sx={{
          mb: 2,
          justifyContent: 'flex-start',
          paddingLeft: '10px',
          textTransform: 'none',
        }}
      >
        Search... <Box component="span" sx={{ ml: 'auto', color: 'text.secondary', fontWeight: 'light' }}>⌘ K</Box>
      </Button>

      <Divider />

      <List>
        <ListItem
          button="true"
          component={Link}
          to="/patients"
          selected={location.pathname === '/patients'}
          onClick={closeDrawer}
          sx={{
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
            '&.Mui-selected:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
          }}
        >
          <ListItemIcon>
            <Group />
          </ListItemIcon>
          <ListItemText primary="Patients" />
        </ListItem>

        <ListItem
          button="true"
          component={Link}
          to="/appointments"
          selected={location.pathname === '/appointments'}
          onClick={closeDrawer}
          sx={{
            'Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            }
          }}
        >
          <ListItemIcon>
            <CalendarToday />
          </ListItemIcon>
          <ListItemText primary="Appointments" />
        </ListItem>

        <ListItem
          button="true"
          component={Link}
          to="/providers-and-inventory"
          selected={location.pathname === '/providers-and-inventory'}
          onClick={closeDrawer}
          sx={{
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
            '&.Mui-selected:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
          }}
        >
          <ListItemIcon>
            <Inventory />
          </ListItemIcon>
          <ListItemText primary="Providers & Inventory" />
        </ListItem>

        <ListItem 
          button="true"
          onClick={handleClickFinancials}
          sx={{
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
            '&.Mui-selected:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
          }}
        >
          <ListItemIcon>
            <AttachMoney />
          </ListItemIcon>
          <ListItemText primary="Financials" />
          {openFinancials ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={openFinancials} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button="true"
              component={Link}
              to="/financials/transactions"
              selected={location.pathname === '/financials/transactions'}
              sx={{
                pl: 4,
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                },
                '&.Mui-selected:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
              }}
              onClick={closeDrawer}
            >
              <ListItemIcon>
                <AccountBalance fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Transactions" />
            </ListItem>
            <ListItem
              button="true"
              component={Link}
              to="/financials/cashflow"
              selected={location.pathname === '/financials/cashflow'}
              sx={{
                pl: 4,
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                },
                '&.Mui-selected:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
              }}
              onClick={closeDrawer}
            >
              <ListItemIcon>
                <TrendingUp fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Cashflow" />
            </ListItem>
            <ListItem
              button="true"
              component={Link}
              to="/financials/pl"
              selected={location.pathname === '/financials/pl'}
              sx={{
                pl: 4,
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                },
                '&.Mui-selected:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
              }}
              onClick={closeDrawer}
            >
              <ListItemIcon>
                <Assessment fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="P&L" />
            </ListItem>
            <ListItem
              button="true"
              component={Link}
              to="/financials/balance-sheet"
              selected={location.pathname === '/financials/balance-sheet'}
              sx={{
                pl: 4,
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                },
                '&.Mui-selected:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
              }}
              onClick={closeDrawer}
            >
              <ListItemIcon>
                <AccountBalance fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Balance Sheet" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          button="true"
          component={Link}
          to="/settings/temperature-humidity"
          selected={location.pathname === '/settings/temperature-humidity'}
          onClick={closeDrawer}
          sx={{
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
            '&.Mui-selected:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
          }}
        >
          <ListItemIcon>
            <Thermostat />
          </ListItemIcon>
          <ListItemText primary="Temperature & Humidity" />
        </ListItem>

        <ListItem 
          button="true"
          onClick={handleClickSettings}
          sx={{
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
            '&.Mui-selected:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
          }}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Settings" />
          {openSettings ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={openSettings} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button="true"
              component={Link}
              to="/settings/treatments"
              selected={location.pathname === '/settings/treatments'}
              sx={{
                pl: 4,
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                },
                '&.Mui-selected:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
              }}
              onClick={closeDrawer}
            >
              <ListItemIcon>
                <MedicalServices fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Treatments" />
            </ListItem>

            <ListItem
              button="true"
              component={Link}
              to="/settings/products"
              selected={location.pathname === '/settings/products'}
              sx={{
                pl: 4,
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                },
                '&.Mui-selected:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
              }}
              onClick={closeDrawer}
            >
              <ListItemIcon>
                <Inventory fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Products" />
            </ListItem>

            <ListItem
              button="true"
              component={Link}
              to="/settings/schedules"
              selected={location.pathname === '/settings/schedules'}
              sx={{
                pl: 4,
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                },
                '&.Mui-selected:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
              }}
              onClick={closeDrawer}
            >
              <ListItemIcon>
                <Schedule fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Schedules" />
            </ListItem>

            <ListItem
              button="true"
              component={Link}
              to="/settings/consents"
              selected={location.pathname === '/settings/consents'}
              sx={{
                pl: 4,
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                },
                '&.Mui-selected:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
              }}
              onClick={closeDrawer}
            >
              <ListItemIcon>
                <MedicalServices fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Informed Consent Templates" />
            </ListItem>

            <ListItem
              button="true"
              component={Link}
              to="/settings/ai-whatsapp"
              selected={location.pathname === '/settings/ai-whatsapp'}
              sx={{
                pl: 4,
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                },
                '&.Mui-selected:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
              }}
              onClick={closeDrawer}
            >
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="AI & WhatsApp Settings" />
            </ListItem>

            <ListItem
              button="true"
              component={Link}
              to="/settings/import-data"
              selected={location.pathname === '/settings/import-data'}
              sx={{
                pl: 4,
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                },
                '&.Mui-selected:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
              }}
              onClick={closeDrawer}
            >
              <ListItemIcon>
                <UploadFile fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Import Data" />
            </ListItem>
          </List>
        </Collapse>
      </List>

      <Divider sx={{ mt: 'auto' }} />

      <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
        <Box sx={{ overflow: 'hidden', flex: 1 }}>
          <Typography
            variant="body1"
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {user?.name}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {user?.email}
          </Typography>
        </Box>
        <IconButton sx={{ ml: 'auto' }} onClick={handleLogout}>
          <Logout />
        </IconButton>
      </Box>

      {/* Modal de búsqueda */}
      <SearchModal open={isSearchModalOpen} onClose={closeSearchModal} />
    </Box>
  );

  return sidebarContent;
}

export default Sidebar;
